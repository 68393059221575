<template>
  <div class="states-edit animatedBox">
    <div class="container fluid">
      <form @submit.prevent="submitForm">
        <div class="card mb-2 pb-1">
          <h4 class="mainTitle lineBottom">State Content</h4>
          <div class="main-contents">
            <fd-select
              class="py-1 px-0"
              :label="'Country'"
              v-model="state.countryId"
              required
              :options="countries"
            >
            </fd-select>
            <fd-input
              class="mt-2 px-0"
              type="text"
              :label="`State Name`"
              v-model="state.name"
              required
            />
          </div>
          <div class="text-right px-2 mt-4 mb-2">
            <button type="submit" class="btn main">Update</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { StateModel } from "@/models";

export default {
  name: "states-edit",
  data() {
    return {
      countries: [],
      state: {
        countryId: "",
        name: ""
      }
    };
  },
  async mounted() {
    await Promise.all([this.loadCountries(), this.loadState()]);
  },
  methods: {
    async loadCountries() {
      this.$store.commit("setIsLoading", true, { root: true });
      try {
        let data = await this.$store.dispatch(
          "manageCountries/getAllCountries",
          {
            page: 1,
            limit: 1000000
          }
        );

        this.countries = this._.cloneDeep(data.data);
        this.$store.commit("setIsLoading", false, { root: true });
      } catch (error) {
        this.$store.commit("setIsLoading", false, { root: true });
        throw error;
      }
    },
    async loadState() {
      const id = this.$route.params.id;
      this.$store.commit("setIsLoading", true, { root: true });
      try {
        const request = await this.$store.dispatch("manageStates/getState", id);

        this.state = StateModel.getStateForEditResponse(
          this._.cloneDeep(request)
        );

        this.$store.commit("setIsLoading", false, { root: true });
        return;
      } catch (error) {
        this.$store.commit("setIsLoading", false, { root: true });
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "An unexpected error occured. Please try again later."
        });
        console.log(error);
        throw error;
      }
    },
    async submitForm() {
      const id = this.$route.params.id;
      this.$store.commit("setIsLoading", true, { root: true });
      try {
        const request = await this.$store.dispatch(
          "manageStates/updateStates",
          {
            id: id,
            data: StateModel.postStatePayload(this.state)
          }
        );

        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "State updated successfully."
        });
        this.$router.push({ path: "/manage-states" });
        this.$store.commit("setIsLoading", false, { root: true });
        return;
      } catch (error) {
        this.$store.commit("setIsLoading", false, { root: true });
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "An unexpected error occured. Please try again later."
        });
        console.log(error);
        throw error;
      }
    }
  }
};
</script>

<style lang="scss">
.states-edit {
  form {
    @extend %formDesign;
  }
}
</style>